.slideshowContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .imageColumn {
    position: relative;
    height: 100vh; /* Adjust based on your layout */
    width: 50%; /* Adjust based on your layout */
    display: flex; /* Enable flexbox */
    justify-content: center; /* Center children vertically */
    align-items: center;
    position: sticky;
    top: 65px;
    z-index: -1;
  }

  .imageColumn img {
    max-height: 70vh; /* Maximum height to fit within the viewport */
    width: auto; /* Width is automatic to maintain aspect ratio */
    object-fit: cover; /* Ensure the images fit without being cropped */
    margin-bottom: 20rem; /* Space between images */
    margin-left: 25%;
    margin-top: 10rem;
    scroll-snap-align: start; /* Snap to the start of the scroll container */
    border-radius: 20px;
    object-fit: contain;
    max-width: 100%;
    /* z-index: -1; */
  }
  
  .fadeInOut {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    object-fit: cover; /* Adjust as needed for your images */
  }
  
  .active {
    opacity: 1;
  }
  
  .textColumn {
    width: 50%; /* Adjust based on your layout */
    overflow-y: auto;
    z-index: -1;
  }

  .header {
    font-size: 6rem;
    margin-top:40vh;
    text-align: left;
    margin-left: 1rem;
    font-family:"Hanken Grotesk", sans-serif;
  }

  .paragraph {
    margin: 1rem 0;
    margin-left: 1.5rem;
    margin-right: 1rem;
    margin-bottom:50vh;
    font-size: 2rem;
    z-index: -1;
    max-width: 70%;
    text-align: left;
    color: #bdbab6;;
    font-family:"Hanken Grotesk", sans-serif;
    /* Any additional styles for your paragraphs */
    text-align: left;
  }

  .imgMobile {
    margin-top: 5vh;
    height: 60vh;
    border-radius: 20px;
  }


  .slideshowMobile {
    background-color: black;
    padding: 0rem 0.4rem 0.5rem 0.4rem;

  }

  .headerMobile {
    font-size: 3.5rem;
    margin-top:5vh;
    text-align: left;
    margin-left: 1rem;
    font-family:"Hanken Grotesk", sans-serif;
    text-align: center;
  }

  .paragraphMobile {
    margin: 1rem 0;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom:10vh;
    font-size: 2rem;
    z-index: -1;
    max-width: 90%;
    text-align: center;
    color: #bdbab6;;
    font-family:"Hanken Grotesk", sans-serif;
    /* Any additional styles for your paragraphs */
  }