/* Write your code here. */
.app-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    min-height: 100vh;
  }
  
  .faqs-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 87%;
    background-color: #ffffff;
    border-radius: 16px;
    padding-top: 32px;
    padding-right: 12px;
    padding-bottom: 32px;
    padding-left: 12px;
    max-width: 550px;
  }
  
  @media screen and (min-width: 768px) {
    .faqs-container {
      width: 90%;
      max-width: 1140px;
      padding: 32px;
    }
  }
  
  .faqs-list {
    width: 100%;
    list-style-type: none;
    padding: 0px;
  }
  
  @media screen and (min-width: 768px) {
    .faqs-list {
      width: 90%;
    }
  }
  
  @media screen and (min-width: 1200px) {
    .faqs-list {
      width: 80%;
    }
  }

  .faqhead {
    margin-top: 30px;
    margin-bottom: 50px;
  }
  
  .heading {
    color: #C89037;
    font-family: "Raleway", sans-serif;
    font-size: 32px;
    font-weight: 700;
  }
  
  @media screen and (min-width: 768px) {
    .heading {
      font-size: 64px;
    }
  }
  