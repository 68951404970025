/* Write your code here. */
.faq-item {
    width: 100%;
    border: 1px solid #d7dae6;
    border-radius: 16px;
    margin-bottom: 24px;
    padding: 16px;
  }
  
  .question-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .question {
    color: #52606d;
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    font-weight: 500;
  }
  
  @media screen and (min-width: 768px) {
    .question {
      font-size: 17px;
    }
  }
  
  .answer {
    color: #52606d;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    font-weight: 300;
  }
  
  @media (max-width: 576px) {
    .answer {
      font-size: 12px;
    }
  }
  
  .button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
  
  @media (max-width: 576px) {
    .image {
      width: 15px;
      height: 15px;
    }
  }
  
  .horizontal-line {
    border: 1px solid #C89037;
  }
  