.slideshowContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap; /* Ensure content wraps on smaller screens */
  }
  
  .imageColumn {
    position: relative;
    height: 100vh; /* Adjust based on your layout */
    width: 55%; /* Adjust based on your layout */
    display: flex; /* Enable flexbox */
    justify-content: center; /* Center children vertically */
    align-items: center;
    position: sticky;
    top: 65px;
    z-index: -1;
  }
  
  .imageColumn img {
    max-height: 50vh; /* Adjusted for smaller height */
    width: auto; /* Width is automatic to maintain aspect ratio */
    object-fit: cover; /* Ensure the images fit without being cropped */
    margin-bottom: 5rem; /* Space between images */
    margin-left: 25%;
    margin-top: 10rem;
    scroll-snap-align: start; /* Snap to the start of the scroll container */
    border-radius: 20px;
    object-fit: contain;
    max-width: 100%;
  }
  
  .fadeInOut {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    object-fit: cover; /* Adjust as needed for your images */
  }
  
  .active {
    opacity: 1;
  }
  
  .textColumn {
    width: 45%; /* Adjust based on your layout */
    overflow-y: auto;
    z-index: -1;
  }
  
  .header {
    font-size: 4rem;
    margin-top: 40vh;
    margin-right: 50px;
    text-align: left;
    margin-left: 1rem;
    color: #000;   
    font-family: "Hanken Grotesk", sans-serif;
  }
  
  .paragraph {
    margin: 1rem 0;
    margin-left: 1.5rem;
    margin-right: 1rem;
    margin-bottom: 50vh;
    font-size: 2rem;
    z-index: -1;
    max-width: 70%;
    text-align: left;
    color: #bdbab6;
    font-family: "Hanken Grotesk", sans-serif;
    /* Any additional styles for your paragraphs */
    text-align: left;
  }
  
  .imgMobile {
    margin: 5vh 10vw 0 10vw; /* Add margins to create space on left and right */
    width: 80vw; /* Ensure it fits within the viewport */
    height: auto; /* Maintain aspect ratio */
    border-radius: 20px;
  }
  
  .slideshowMobile {
    background-color: #fff;
    padding: 0rem 0.4rem 0.5rem 0.4rem;
  }
  
  .headerMobile {
    font-size: 3.5rem;
    margin-top: 5vh;
    text-align: left;
    margin-left: 1rem;
    font-family: "Hanken Grotesk", sans-serif;
    text-align: center;
  }
  
  .paragraphMobile {
    margin: 1rem 0;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 10vh;
    font-size: 2rem;
    z-index: -1;
    max-width: 90%;
    text-align: center;
    color: #bdbab6;
    font-family: "Hanken Grotesk", sans-serif;
    /* Any additional styles for your paragraphs */
  }
  
  /* Media Queries for responsiveness */
  @media (max-width: 768px) {
    .slideshowContainer {
      flex-direction: column;
    }
  
    .imageColumn, .textColumn {
      width: 100%;
    }
  
    .imageColumn img {
      max-height: 30vh;
      margin: 1rem auto;
    }
  
    .header {
      font-size: 3rem;
      margin-top: 5vh;
      text-align: center;
    }
  
    .paragraph {
      font-size: 1.5rem;
      margin-bottom: 5vh;
      text-align: center;
      max-width: 90%;
    }
  }
  
  @media (max-width: 480px) {
    .header {
      font-size: 2rem;
    }
  
    .paragraph {
      font-size: 1rem;
    }
  
    .imageColumn img {
      max-height: 20vh;
    }
  }
  